<template>
  <bg-style class="single-goods" :class="{ 'is-mobile': $store.getters.isMobile }" :bg="model.background">
    <div class="single-goods__content">
      <rich-text v-if="model.titleVisible" v-model="model.title" :class="$store.getters.isDesktop && 'xl:text-[40px]'" :disabled="!editing" :editing="editing" :stroke.sync="model.titleStroke" class="text-[20px] w-full relative title" theme="snow"/>
      <rich-text v-if="model.subtitleVisible" v-model="model.subtitle" :class="{ 'text-24': $store.getters.isDesktop }" :disabled="!editing" :editing="editing" :stroke.sync="model.subTitleStroke" class="mt-3 text-14 relative" theme="snow"/>
      <div class="single-goods__list mt-[40px] grid grid-cols-2">
        <div class="item">
          12121
        </div>
      </div>
    </div>
  </bg-style>
</template>

<script>
import RichText from "~/components/richText/index.vue";

export default {
  name: "SingleGoodsWidget",
  components: {RichText},
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="less">
.single-goods {
  &__content {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
  }
}
</style>
