<template>
  <div class="single-goods__edit">
    <menu-item label="背景设置">
      <menu-fill :target="model.background" target-key="background" />
      <menu-background-image class="mt-2" :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="布局">
      <menu-switch v-model="model.titleVisible" label="标题" target-key="titleVisible" />
      <menu-switch v-model="model.subtitleVisible" label="描述" target-key="subtitleVisible" />
    </menu-item>
    <menu-item label="商品背景圆角">
      <menu-slider v-model="model.goodsRadius" target-key="goodsRadius" />
    </menu-item>
    <menu-button :help-text="$t('menu.copyBlock')" class="mt-4" icon="add" type="ghost-copy" @click.native.stop="handleClone">{{ $t('menu.createCopy') }}</menu-button>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem.vue"
import MenuFill from "~/components/menu/menuFill.vue"
import RemoveBtn from "~/components/common/RemoveBtn.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";
import MenuSwitch from "~/components/menu/menuSwitch.vue";
import MenuSlider from "~/components/menu/menuSlider.vue";
import MenuButton from "~/components/menu/menuButton.vue";
import {StoreWidgetModel} from "~/site/model";

export default {
  name: "SingleGoodsWidgetEdit",
  components: {MenuButton, MenuSlider, MenuSwitch, MenuBackgroundImage, RemoveBtn, MenuFill, MenuItem},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    handleClone() {
      this.$emit('clone', new StoreWidgetModel(this.model.__getOptions()))
    }
  }
}
</script>

<style scoped lang="less">

</style>
